import "../../css/pages/ContestPresentation.scss";
import React, { Component } from "react";
import TimeCounter from "../elements/TimeCounter";
import PropTypes from "prop-types";
import Alert from "../elements/Alert";

/*
    Landing page containing the information of the contest
    and a form required by the user to subscribe.
*/
class ContestPresentation extends Component {
  constructor(props) {
    super(props);
    this.inputEmail = this.props.acemail || "";
    this.inputFirstName = this.props.acname || "";
    this.state = { alertMessage: "" };
  }

  /*
        Format the price offered with space every thousand 
        ex: 1000€ => 1 000€
    */
  displayReward(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  }

  /*
        Wrap the time counter and adapt the display 
        regarding to the window size.
    */
  getRemainingTime() {
    let specComponent = undefined;
    if (window.innerWidth >= 650) {
      specComponent = (
        <div className="specifications large">
          <div className="winners">
            <span>{this.props.winners} gagnants</span>
          </div>
          <TimeCounter dateThreshold={this.props.dateThreshold} className="contestCounter" />
          <div className="reward">
            <span>
              Valeur : {this.displayReward(this.props.reward)} {this.props.currency}
            </span>
          </div>
        </div>
      );
    } else {
      specComponent = (
        <div className="specifications small">
          <TimeCounter dateThreshold={this.props.dateThreshold} className="contestCounter" />
          <div className="winnerAndReward">
            <div className="winners">
              <span>{this.props.winners} gagnants</span>
            </div>
            <div className="reward">
              <span>
                Valeur : {this.displayReward(this.props.reward)} {this.props.currency}
              </span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="timeWrapper">
        <div className="lineUp">
          <span>Temps Restant</span>
        </div>
        {specComponent}
        <svg className="lineDown" height="100%" width="100%">
          <line x1="0" y1="10%" x2="48%" y2="10%" />
          <line x1="48%" y1="10%" x2="50%" y2="90%" />
          <line x1="50%" y1="90%" x2="52%" y2="10%" />
          <line x1="52%" y1="10%" x2="100%" y2="10%" />
        </svg>
      </div>
    );
  }

  /*
        Validate the subscription when the user want to participate.
    */
  onClick = (e) => {
    e.preventDefault();
    if (this.inputFirstName.length === 0) {
      this.setState({
        alertMessage: "Veuillez renseigner votre prénom.",
      });
      return;
    } else if (this.inputEmail.length === 0) {
      this.setState({
        alertMessage: "Veuillez renseigner votre adresse email.",
      });
      return;
    } else if (
      this.inputEmail.indexOf("@") === -1 ||
      this.inputEmail.split("@")[1].indexOf(".") === -1
    ) {
      this.setState({
        alertMessage: "Adresse email invalide, il manque un point après le @. 🧐",
      });
      return;
    }
    let callback = (captchaToken) => {
      this.props.callback(this.inputEmail, this.inputFirstName, captchaToken);
    };
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6LfpWlcbAAAAAGRDys1vOYjjxi7bBSkYSRKx1cj4", {
          action: "submit",
        })
        .then(callback)
        .catch((err) => {});
    });
  };

  /*
        Return the description of the contest.
    */
        getContestDescription() {
          return (
            <p className="description">
              1 000 mercis pour votre participation 😍 ! 
              Si vous voulez suivre les aventures de Fizzer, rejoignez-nous sur{" "}
              <a href="https://www.facebook.com/fizzer.official">Facebook</a> &amp;{" "}
              <a href="https://www.instagram.com/fizzer_app/?hl=fr">Instagram</a> !<br/>
              <br/>
              Pour revoir votre classement, vous pouvez vous connecter ci-dessous.<br/>
              <br/>
              Très bel été ☀️ ! 
            </p>
          );
        }

  /*
        Return the form required to subscribe.
    */
  getEmailForm() {
    return (
      <form className="subscribe" onSubmit={this.onClick}>
        <div className="vAlign">
          <label>Prénom</label>
          <input
            defaultValue={this.props.acname}
            onKeyUp={(x) => {
              this.inputFirstName = x.currentTarget.value;
            }}
            placeholder="Ex : Julie"
          />
        </div>
        <div className="vAlign">
          <label>Email</label>
          <input
            defaultValue={this.props.acemail}
            onKeyUp={(x) => {
              this.inputEmail = x.currentTarget.value;
            }}
            placeholder="Ex : thomas@gmail.com"
            type="email"
          />
        </div>
        <button type="submit" className="loginEmailButton" click="submitForm()">
          C'est parti
        </button>
      </form>
    );
  }

  render() {
    return (
      <div className="ContestPresentation">
        <Alert
          text={this.state.alertMessage}
          onClose={() => {
            this.setState({ alertMessage: "" });
          }}
        />
        <header>
        </header>
        {this.getRemainingTime()}
        <br/>
        <br/>
        {this.getContestDescription()}
        {this.getEmailForm()}
      </div>
    );
  }
}

ContestPresentation.propTypes = {
  dateThreshold: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  winners: PropTypes.number.isRequired,
  reward: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

export default ContestPresentation;
