import React, { Component } from "react";
import PropTypes from "prop-types";
import { getTotalPoints } from "../model/Utils";
import { getRanking } from "../model/Database";
import "../../css/elements/Ranking.scss";

/*
    Top 10 ranking table with the user having the more points.
*/
class Ranking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ranking: [],
    };
    this.interval = undefined;
  }

  /*
        Update the data by calling the backend.
    */
  update = () => {
    getRanking(this.props.user.id)
      .then((res) => {
        this.setState({ ranking: res });
      })
      .catch((x) => {});
  };

  componentDidMount = () => {
    this.update();
    this.interval = setInterval(this.update, 10000);
  };

  componentWillUnmount = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
  };

  /*
        Evaluate the number of missing points required to enter the top 3.
    */
  getRemainingPointsToTop3() {
    return Math.max(this.state.ranking[2]["total_points"] - getTotalPoints(this.props.user), 0);
  }



  /*
        Build the ranking table.
    */
  getTable() {
    let tableBody = this.state.ranking.map((e, i) => {
      return (
        <tr key={i} className={e["is_user"] ? "isUser" : ""}>
          <td>{e["position"]}</td>
          <td>{e["first_name"]}</td>
          <td>{e["total_points"]} pts</td>
        </tr>
      );
    });
    return (
      <table>
        <tbody>{tableBody}</tbody>
      </table>
    );
  }

  render() {
    return (
      <div className="Ranking">
        {this.getTable()}
      </div>
    );
  }
}

Ranking.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Ranking;
