import "../../css/elements/RewardMessage.scss";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { getTotalPoints } from "../model/Utils";

/*
    Display the info message regarding to the current state
    to better inform the user about the contest result.
*/
class RewardMessage extends Component {
  render() {
    if (this.props.alertType === "rewardValidation") {
      return (
        <div className="RewardMessage">
          Merci pour le partage, vous êtes incroyable ! 😍
          <br /> Les points seront ajoutés dès le premier clic sur votre publication.
        </div>
      );
    } else if (this.props.alertType === "rewardUpdate") {
      return (
        <div className="RewardMessage">
          Votre participation a été prise en compte ! 😍
          <br />
          Vous passez maintenant à {getTotalPoints(this.props.user)} points.
        </div>
      );
    } else {
      return (
        <div className="RewardMessage">
          Bravo ! 🥳
          <br />
          Vous avez maintenant {getTotalPoints(this.props.user)} points.
        </div>
      );
    }
  }
}

RewardMessage.propTypes = {
  alertType: PropTypes.string,
  user: PropTypes.object.isRequired,
};

export default RewardMessage;
