import "../../css/elements/TimeCounter.scss";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/*
    Display a time counter to a given date threshold.
*/
function TimeCounter(props) {
  /*
        Time left until the date threshold given as parameter.
    */
  const getTimeLeft = () => {
    let now = new Date();
    var offset = now.getTimezoneOffset() + 60;
    let difference = new Date(props.dateThreshold) - now - offset * 60 * 1000;
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  /*
        Update the time left every second.
    */
  const [timeLeft, setTimeLeft] = useState(getTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(getTimeLeft());
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <div className="TimeCounter">
      <div className="delay_wrapper days">
        <div className="value">{timeLeft.days}</div>
        <div className="label">Jours</div>
      </div>
      <span className="delimiter">:</span>
      <div className="delay_wrapper hours">
        <div className="value">{timeLeft.hours.toString().padStart(2, "0")}</div>
        <div className="label">Heures</div>
      </div>
      <span className="delimiter">:</span>
      <div className="delay_wrapper minutes">
        <div className="value">{timeLeft.minutes.toString().padStart(2, "0")}</div>
        <div className="label">Minutes</div>
      </div>
      <span className="delimiter">:</span>
      <div className="delay_wrapper seconds">
        <div className="value">{timeLeft.seconds.toString().padStart(2, "0")}</div>
        <div className="label">Secondes</div>
      </div>
    </div>
  );
}

TimeCounter.propTypes = {
  dateThreshold: PropTypes.object.isRequired,
};

export default TimeCounter;
