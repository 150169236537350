import '../../css/elements/Alert.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Alert extends Component {
	render() {
		return (
			<div
				className={`Alert verticalCenter ${
					(this.props.text || '').length > 0 ? 'active' : 'inactive'
				}`}
			>
				<div className="wrapper">
					<p>{this.props.text || ''}</p>
					<button className="linkButton" onClick={this.props.onClose}>
						Fermer
					</button>
				</div>
			</div>
		);
	}
}

Alert.propTypes = {
	text: PropTypes.string,
	onClose: PropTypes.func.isRequired,
};

export default Alert;
