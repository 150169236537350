/*
    Count the total number of points for a given user.
*/

export function getTotalPoints(user) {
  let totalReward = 2;
  if (user["whatsapp_share"]) {
    totalReward += 3;
  }
  if (user["facebook_share"]) {
    totalReward += 3;
  }
  if (user["facebook_like"]) {
    totalReward += 2;
  }
  if (user["instagram_follow"]) {
    totalReward += 2;
  }
  if (user["messenger_share"]) {
    totalReward += 3;
  }
  if (user["sms_share"]) {
    totalReward += 3;
  }
  if (user["linkedin_like"]) {
    totalReward += 2;
  }
  totalReward += user["total_referrals"] * 3;
  totalReward += user["extra_points"] || 0;
  return totalReward;
}

/*
    Return the os used by the user.
*/
export function getOs() {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
    return "ios";
  } else if (userAgent.match(/Android/i)) {
    return "android";
  } else {
    return "desktop";
  }
}

/*
    Return true if an action is available for the user context.
    ex : a whatsapp share is only displayed on mobile.
*/
export function isActionCompatible(action) {
  let os = getOs();

  if (os === "desktop" && action === "whatsapp_share") {
    return false;
  } else if (os === "desktop" && action === "sms_share") {
    return false;
  } else {
    return true;
  }
}

/*
	Return the browser type of the client.
	It detects special browser such as facebook, messenger, 
	linkedin (in-app native)
*/
export function getBrowserType() {
  let userAgent = window.navigator.userAgent;
  if (typeof navigator !== "undefined" && /LinkedInApp/i.test(userAgent)) {
    return "linkedin";
  } else if (userAgent.indexOf("[FB") > -1 && userAgent.indexOf("Messenger") === -1) {
    return "facebook";
  } else if (userAgent.indexOf("[FB") > -1 && userAgent.indexOf("Messenger") > -1) {
    return "messenger";
  } else {
    return "other";
  }
}

/*
    Return all action's parameters (social share details).
*/
export function getActionParameters(userId) {
  let browserType = getBrowserType();
  let os = getOs();

  let getOpenLink = (src) => {
    let isLinkedin = browserType === "linkedin";
    let message =
      "Fizzer%2C%20l%E2%80%99application%20qui%20permet%20d%E2%80%99envoyer%20ses%20cartes%20postales%2C%20faire-part%2C%20gazettes%20et%20albums%20personnalis%C3%A9s%20avec%20ses%20propres%20photos%2C%20a%20organis%C3%A9%20un%20GRAND%20JEU%20CONCOURS%20pour%20l%E2%80%99%C3%A9t%C3%A9%20avec%20plein%20de%20cadeaux%20%C3%A0%20gagner%20%21%21%0AParticipe%20juste%20ici%20%3A%20";
    return `${src !== "facebook_share" && src !== "messenger_share" && !isLinkedin ? message : ""}${
      !isLinkedin
        ? `https%3A%2F%2Fwww.concours.fizzer.com%3Freferrer=`
        : `https://www.concours.fizzer.com?referrer=`
    }${userId}${!isLinkedin ? `%26source=` : `&source=`}${src}&`;
  };

  let getCopyLink = (action) =>
    `https://www.concours.fizzer.com/?referrer=${userId}&source=${action}&`;

  let facebookLikeUrl = (function () {
    if (os === "ios" && !(browserType === "linkedin")) {
      return "fb://page/?id=263390307861072";
    } else if (os === "android" && !(browserType === "linkedin")) {
      return "fb://page/263390307861072";
    } else {
      return "https://www.facebook.com/fizzer.official/";
    }
  })();

  let defaultLinkType = browserType === "linkedin" ? "copyLink" : "openLink";

  return {
    facebook_like: {
      name: "facebook_like",
      description: "Nous suivre sur Facebook",
      immediatReward: 2,
      url: facebookLikeUrl,
      linkType: defaultLinkType,
      hasAutomaticValidation: true,
    },
    instagram_follow: {
      name: "instagram_follow",
      description: "Nous suivre sur Instagram",
      immediatReward: 2,
      url: "https://www.instagram.com/fizzer_app/",
      linkType: defaultLinkType,
      hasAutomaticValidation: true,
    }
  };
}
