import "../../css/pages/ContestValidation.scss";
import React, { Component } from "react";
import PropTypes from "prop-types";
import Actions from "../elements/Actions";
import { getTotalPoints } from "../model/Utils";
import Ranking from "../elements/Ranking";
import RewardMessage from "../elements/RewardMessage";
import CopyLink from "../elements/CopyLink";

/* 
    Page displayed after a user has registered.
    It contains actions (social share) the user can perform
    to improve his score in the contest.    
*/
class ContestValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertType: "rewardMessage",
    };
  }

  /*
        If an action is made (social share), update state 
        and run the given callback.
    */
  callback = (action) => {
    if (["messenger_share", "whatsapp_share", "facebook_share", "sms_share"].indexOf(action) > -1) {
      setTimeout((x) => {
        this.setState({ alertType: "rewardValidation" });
      }, 10000);
    }
    this.props.callback(action);
  };

  componentDidUpdate(prevParams) {
    if (getTotalPoints(prevParams.user) !== getTotalPoints(this.props.user)) {
      this.setState({ alertType: "rewardUpdate" });
    }
  }

  render() {
    return (
      <>
        <div className="ContestValidation">
          <header></header>
          <br />
          <p className="description">
          ​​Le concours s'est terminé le 30 juillet à minuit. Un email sera envoyé rapidement à tous les gagnants pour leur indiquer leur cadeau. N'hésitez pas à suivre nos aventures sur{" "}
          <a href="https://www.instagram.com/fizzer_app/?hl=fr">Instagram</a> &amp;{" "}
          <a href="https://www.facebook.com/fizzer.official">Facebook</a> !
          </p>
          <h2>Nous suivre sur Instagram &amp; Facebook &nbsp;👇</h2>

          <Actions
            callback={this.callback}
            userId={this.props.user.id}
            actions={{
              facebook_like: this.props.user["facebook_like"],
              instagram_follow: this.props.user["instagram_follow"],
            }}
          />

          <br />
          <p className="description">
          N’hésitez pas à aller faire un tour sur la page{" "}
          <a href="https://www.fizzer.com/fr/concours-recompenses/" target="_blank" rel="noreferrer">
          vos récompenses
        </a>{" "}
           pour voir le cadeau que vous avez gagné en fonction de votre classement.
          </p>

          <h2>Votre classement final 🔥</h2>

          <Ranking user={this.props.user} />

          <br />
          <p className="description">
            Merci 1 000 fois pour votre participation !
          </p>

        </div>
      </>
    );
  }
}

ContestValidation.propTypes = {
  user: PropTypes.object.isRequired,
  dateThredhold: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
};

export default ContestValidation;
