import "../css/Main.scss";
import md5 from "md5-hash";
import React, { Component } from "react";
import ContestPresentation from "./pages/ContestPresentation";
import ContestValidation from "./pages/ContestValidation";
import { postUser, getUser, validateAction } from "./model/Database";
import Cookies from "universal-cookie";

const DATE_THRESHOLD = new Date(2021, 6, 30, 19, 30, 0, 0);
const REWARD = 15000;
const CURRENCY = "€";
const WINNER = 1000;

/*
  Main component wrapping all and managing the logic.
*/
class Main extends Component {
  constructor(props) {
    super(props);
    let urlParams = new URLSearchParams(window.location.search);
    // initial user description

    this.state = {
      user: {
        id: undefined,
        first_name: undefined,
        email: undefined,
        timestamp: Math.floor(new Date().getTime() / 1000),
        whatsapp_share: false,
        messenger_share: false,
        facebook_like: false,
        facebook_share: false,
        instagram_follow: false,
        linkedin_like: false,
        sms_share: false,
        referrer: urlParams.get("referrer") || "",
        referrer_source: urlParams.get("source") || "",
        total_referrals: 0,
      },
      isOverlayActive: false,
    };

    this.cookies = new Cookies();
    this.pollingUser = undefined;

    this.acname = urlParams.get("acname");
    this.acemail = urlParams.get("acemail");
  }

  componentDidMount() {
    let id = new URLSearchParams(window.location.search).get("login");
    id = id ? id : this.cookies.get("id");
    if (id) {
      getUser(id)
        .then((res) => {
          this.setState({ user: res });
        })
        .catch((err) => {});
    }

    if (this.state.user.referrer) {
      validateAction(this.state.user.referrer, this.state.user.referrer_source).catch((err) => {});
    }

    this.pollingUser = setInterval(() => {
      if (this.state.user.id) {
        getUser(this.state.user.id)
          .then((res) => {
            this.setState({ user: res });
          })
          .catch((err) => {});
      }
    }, 10000);
  }

  componentWillUnmount = () => {
    if (this.pollingUser) {
      clearInterval(this.pollingUser);
    }
  };

  /*
    Callback send in the ContestPresentation to create (or to recover) 
    the user in database when he subscribes.
  	*/
  subscribe = (email, firstName, captchaToken) => {
    email = email.toLowerCase().trim();
    firstName = firstName.trim().toLowerCase();
    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    let userId = md5(email).slice(0, 12);

    this.cookies.set("id", userId, {
      path: "/",
      maxAge: 2592000,
      sameSite: "none",
      secure: true,
    });
    this.setState((prevState) => {
      prevState.user.id = userId;
      prevState.user.email = email;
      prevState.user.first_name = firstName;
      window.scrollTo(0, 0);
      return prevState;
    });

    getUser(userId)
      .then((res) => {
        this.setState({ user: res });
      })
      .catch((err) => {
        let newUser = { ...this.state.user };
        newUser["id"] = userId;
        newUser["email"] = email;
        newUser["first_name"] = firstName;
        postUser(newUser, captchaToken).then((isAdded) => {
          if (!isAdded) {
            this.setState((prevState) => {
              prevState.user.id = undefined;
              prevState.user.email = undefined;
              prevState.user.first_name = undefined;
              window.scrollTo(0, 0);
              return prevState;
            });
          }
        });
      });
  };

  /*
    Callback send in the ContestPresentation to validate an action (social share)
    in database. Most of the actions are validated outside the project.
  	*/
  update = (action) => {

  };

  /*
    Return the contest presentation.
  	*/
  getPresentation() {
    return (
      <ContestPresentation
        dateThreshold={DATE_THRESHOLD}
        reward={REWARD}
        currency={CURRENCY}
        winners={WINNER}
        callback={this.subscribe}
        acname={this.acname}
        acemail={this.acemail}
      />
    );
  }

  /*
    Return the contest validation
  	*/
  getValidation() {
    return (
      <ContestValidation
        user={this.state.user}
        dateThredhold={DATE_THRESHOLD}
        callback={this.update}
      />
    );
  }

  render() {
    return (
      <>
        <div className={`Main ${this.state.isOverlayActive ? "noScroll" : ""}`}>
          {this.state.user.email ? this.getValidation() : this.getPresentation()}
          <footer></footer>
        </div>
        <div className="sponsor">
          <a href="https://www.swingviral.com" target="_blank" rel="noreferrer">
            Sponsorisé par Swing Viral
          </a>
        </div>
      </>
    );
  }
}

export default Main;
