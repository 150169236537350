import { getOs, getBrowserType } from "../model/Utils";
const API_URL = "https://gvhgnqjmsl.execute-api.eu-west-1.amazonaws.com/production";
//const API_URL = "https://gvhgnqjmsl.execute-api.eu-west-1.amazonaws.com/staging";

/*
    Post a user information into the database.
*/
export function postUser(user, captchaToken) {
  let data = {
    user: {
      id: user["id"],
      first_name: user["first_name"],
      email: user["email"],
      timestamp: user["timestamp"],
      whatsapp_share: user["whatsapp_share"] || false,
      facebook_share: user["facebook_share"] || false,
      messenger_share: user["messenger_share"] || false,
      linkedin_like: user["linkedin_like"] || false,
      facebook_like: user["facebook_like"] || false,
      instagram_follow: user["instagram_follow"] || false,
      sms_share: user["sms_share"] || false,
      os: getOs(),
      browser: getBrowserType(),
      extra_points: 0,
      referrer: user["referrer"] || "",
      referrer_source: user["referrer_source"],
    },
    captcha_token: captchaToken,
  };
  return fetch(API_URL, {
    method: "POST",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        return { success: false };
      }
    })
    .then((res) => {
      return res["success"];
    });
}

/*
    Validate an action (social share) in the database.
*/
export function validateAction(userId, source) {
  let data = {
    user_id: userId,
    source: source,
  };
  return fetch(API_URL + "/validate", {
    method: "POST",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

/*
    Get a user from the database using it's id.
*/
export function getUser(id) {
  return fetch(`${API_URL}?id=${id}`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error("Something went wrong");
      }
    })
    .then((res) => {
      if (!res["is_found"]) {
        throw new Error("Something went wrong");
      } else {
        res = res.user;
        delete res["os"];
        delete res["browser"];
      }
      return res;
    });
}

/*
    Return the data required for the ranking 
*/
export function getRanking(user_id) {
  return fetch(`${API_URL}/ranking?id=${user_id}`, {
    method: "GET",
    mode: "cors",
  }).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      throw new Error("Something went wrong");
    }
  });
}
