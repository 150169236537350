import "../../css/elements/Actions.scss";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { isActionCompatible, getActionParameters, getBrowserType } from "../model/Utils";
import CopyLink from "./CopyLink";

/*
    Render the actions (social share) performed by the user.
*/
class Actions extends Component {
  constructor(props) {
    super(props);
    this.actionParams = getActionParameters(props.userId);
  }

  /*
        Update the current state, open the url, and call the callback 
        when an action (social share) is performed.
    */
  update = (actionParam) => {
    this.props.callback(actionParam.name);
    if (actionParam.linkType === "openLink") {
      if (getBrowserType() === "facebook" || getBrowserType() === "messenger") {
        setTimeout(() => {
          window.open(actionParam.url, "_blank");
        }, 800);
      } else {
        window.open(actionParam.url, "_blank");
      }
    }
  };

  render() {
    return (
      <div className={`Actions ${this.props.className || ""}`}>
        <table>
          <tbody>
            {Object.keys(this.props.actions).map((x) => {
              return (
                <ActionRow
                  key={x}
                  isChecked={this.props.actions[x]}
                  param={this.actionParams[x]}
                  callback={this.update}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

Actions.propTypes = {
  callback: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  className: PropTypes.string,
};

export default Actions;

/*
	A table row with an action to validate (social share)
*/
class ActionRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClicked: false,
    };
  }

  /*
        Return the status component displayed at the top right of the row.
    */
  getStatusComponent(actionParam, designState) {
    let statusButton = undefined;

    if (actionParam.referralReward) {
      statusButton = (
        <span className="referralReward">
          +{actionParam.referralReward} par
          <br />
          <span className="filleul">filleul</span>
        </span>
      );
    }

    statusButton = (
      <div className={`reward ${designState}`}>
        <span className="immediatReward">+{actionParam.immediatReward}</span>
        {statusButton}
      </div>
    );

    return statusButton;
  }

  render() {
    let param = this.props.param;
    let action = this.props.param.name;
    let isDisplayed = isActionCompatible(action) || this.props.isChecked;
    if (!isDisplayed || param === undefined) {
      return <></>;
    }
    let designState = this.props.isChecked
      ? "active"
      : this.state.isClicked
      ? "performed"
      : "inactive";

    let description =
      param.linkType === "openLink" ? (
        param.description
      ) : (
        <CopyLink url={param.url} text={param.description} activate={this.state.isClicked} />
      );

    return (
      <tr
        className={param.linkType}
        onClick={() => {
          this.setState({ isClicked: true });
          this.props.callback(param);
        }}
      >
        <td>
          <div className={`logo ${action}`}></div>
        </td>
        <td>
          <div className={`description ${designState}`}>{description}</div>
        </td>
      </tr>
    );
  }
}

ActionRow.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  param: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
};
