import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../css/elements/CopyLink.scss";
import { getOs, getBrowserType } from "../model/Utils";

/*
    Create button able to copy a text in the clipboard.
*/
class CopyLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: props.text,
      isPopupDisplayed: false,
    };
    this.callback = props.callback || (() => {});
    this.revertToButtonDelay = props.revertToButtonDelay || 1500;
    this.copyText = props.copyText ? props.copyText : "Votre lien est copié";
    this.noClipboardSaver = props.noClipboardSaver || "popup";
  }

  componentDidUpdate(prevProps) {
    if (!prevProps["activate"] && this.props["activate"] === true) {
      this.copyLink();
    }
  }

  /*
        Copy the text in the clipboard and display "Copié" during revertToButtonDelay ms.
        if revertToButtonDelay equals -1 the text "Copié" does not rollback to the original
        button text.
    */
  copyLink = () => {
    if (this.hasClipboardCopy()) {
      navigator.clipboard.writeText(this.props.url);
      this.setState({ text: this.copyText });
      if (this.revertToButtonDelay !== -1) {
        setTimeout(() => {
          this.setState({
            text: this.props.text,
          });
        }, 1500);
      }
    } else {
      this.setState({ isPopupDisplayed: true });
    }

    this.callback();
  };

  /*
		Return true if the browser supports copy clipboard
	 */
  hasClipboardCopy() {
    let os = getOs();
    let browser = getBrowserType();
    return !(os === "android" && (browser === "facebook" || browser === "messenger"));
  }

  getCopyLinkComponent() {
    let hasClipboard = this.hasClipboardCopy();
    if (!hasClipboard && this.noClipboardSaver === "popup") {
      let designState = this.state.isPopupDisplayed ? "active" : "inactive";
      return (
        <>
          <div className={`linkOverlaySaver ${designState}`}>
            <div className="wrapper">
              <p className="linkText">{this.props.url}</p>
              <button
                className="closeButton"
                onClick={() => {
                  this.setState({
                    isPopupDisplayed: false,
                  });
                }}
              >
                fermer
              </button>
            </div>
          </div>
          <button className="copyButton" onClick={this.copyLink}>
            {this.state.text}
          </button>
        </>
      );
    } else if (!hasClipboard && this.noClipboardSaver === "field") {
      return <p className="linkFieldSaver linkText">{this.props.url}</p>;
    } else {
      return (
        <button className="copyButton" onClick={this.copyLink}>
          {this.state.text}
        </button>
      );
    }
  }

  render() {
    return (
      <div className={`CopyLink ${this.props.className || ""}`}>{this.getCopyLinkComponent()}</div>
    );
  }
}

CopyLink.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  callback: PropTypes.func,
  revertToButtonDelay: PropTypes.number,
  className: PropTypes.string,
  copyText: PropTypes.string,
  noClipboardSaver: PropTypes.string,
  activate: PropTypes.bool,
};

export default CopyLink;
